import { ACTIONS } from "../../enums/actions";

export const getCompany = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_COMPANY,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getCompanies = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_COMPANIES,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const createCompany = (
  payload: any,
  onSuccess?: (pk: number) => void,
  onFail?: (data: any) => void
) => ({
  type: ACTIONS.CREATE_COMPANY,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const updateCompany = (
  payload: any,
  onSuccess?: (pk: number) => void,
  onFail: Function | null = null
) => ({
  type: ACTIONS.UPDATE_COMPANY,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getCompanySummary = (
  payload: any,
  onSuccess?: (data: any) => void,
  onFail: Function | null = null
) => ({
  type: ACTIONS.GET_COMPANY_SUMMARY,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const downloadExcelSummaryReport = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.DOWNLOAD_COMPANY_SUMMARY_EXCEL,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const download8KReport = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.DOWNLOAD_8K_SUMMARY_EXCEL,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const download10KReport = (
  payload: any,
  onSuccess: Function | null = null,
  onFail: Function | null = null
) => ({
  type: ACTIONS.DOWNLOAD_10K_SUMMARY_EXCEL,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const deleteCompany = (
  payload: any,
  onSuccess?: (data: any) => void,
  onFail: Function | null = null
) => ({
  type: ACTIONS.DELETE_COMPANY,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});