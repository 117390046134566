import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IRootState } from "../../interfaces/store";
import { useSelector } from "../../store";
import { useHistory } from "react-router";
import { arrayFromKeyedObject } from "../../utils/collection-utils";
import CompaniesTable from "./CompaniesTable";
import { getAllMinesGroupByCompany } from "../../store/actions/mineActions";
import {
  ICompany,
  ICompanyWithCounts,
} from "../../interfaces/model/MSHAPCT/company.interface";
import { IMine } from "../../interfaces/model/MSHAPCT/mine.interface";
import { COLORS } from "../../enums/layout-enums";
import { setClientOptions } from "../../store/actions/clientActions";
import { MAIN_TAB } from "../../interfaces/store/main";
import {
  getAccountsByFlag,
  getAdminEmailSettings,
} from "../../store/actions/accountActions";
import { IAccountLogin } from "../../interfaces/model/MSHAPCT/account-login.interface";
import AccountsTable from "./AccountTable";
import AddCompanyModal from "./AddCompanyModal";
import { ACCOUNT_TYPE } from "../../enums/citation-enums";
import AdminEmailSettingsTable from "./AdminEmailSettingsTable";
import { ACCOUNT_FLAGS } from "../../enums/account-enums";
import { hasFlag } from "../../utils/bitwise-utils";
import AdminUserModal from "./AdminUserModal";
import { ICompanyAccount } from "../../interfaces/model/MSHAPCT/company-account.interface";
import { format } from "date-fns";

interface IAdministrationProps {}

const Administration: React.FC<IAdministrationProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [companyDataKey, setCompanyDataKey] = useState(0);
  const [accountDataKey, setAccountDataKey] = useState(0);
  const [adminEmailDataKey, setAdminEmailDataKey] = useState(0);
  const [companiesWithMines, setCompaniesWithMines] = useState(
    [] as ICompanyWithCounts[]
  );
  const [systemAdminAccounts, setSystemAdminAccounts] = useState([] as any[]);
  const [systemAdminEmails, setSystemAdminEmails] = useState([] as any[]);
  const [addCompanyModalOpen, setAddCompanyModalOpen] = useState(false);
  const [addAdminUserModalOpen, setAddAdminUserModalOpen] = useState(false);
  const [addAdminUserModalId, setAddAdminUserModalId] = useState(1);
  const [selectedAccountLogin, setSelectedAccountLogin] = useState(0);
  const companies = useSelector((state: IRootState) => state.main.companies);

  const mines = useSelector((state: IRootState) => state.main.mines);

  const accountLogins = useSelector(
    (state: IRootState) => state.main.account_logins
  );

  const accountLogin = useSelector(
    (state: IRootState) => state.main.account_login
  );

  const adminEmailSettings = useSelector(
    (state: IRootState) => state.main.admin_email_settings
  );
  const companyAccounts = useSelector(
    (state: IRootState) => state.main.company_accounts
  );
  
  useEffect(() => {
    dispatch(getAllMinesGroupByCompany());
    dispatch(setClientOptions({ selected_tab: MAIN_TAB.ADMINISTRATION }));
    dispatch(getAccountsByFlag({ flags: [9] }));
    dispatch(getAdminEmailSettings());
  }, []);

  useEffect(() => {
    const accounts = arrayFromKeyedObject(accountLogins);
    setSystemAdminAccounts(
      accounts.filter(
        (a: IAccountLogin) => a.accountFlag === ACCOUNT_TYPE.SYSTEM_ADMIN || a.accountFlag === 1
      )
    );
    setAccountDataKey(accountDataKey + 1);
  }, [accountLogins]);


  /// Combine
  useEffect(() => {
    const companiesWithCounts = arrayFromKeyedObject(companies) as ICompanyWithCounts[];
    const companiesAccount = arrayFromKeyedObject(companyAccounts) as ICompanyAccount[];
    const minesArr = arrayFromKeyedObject(mines);
   
    // Initialize mineCount and userCount for each company
    companiesWithCounts.forEach((company: ICompanyWithCounts) => {
      company.mineCount = 0;
      company.userCount = 0;
      company.enabledUserCount = 0;
    });
  
    // Update mineCount
    minesArr.forEach((mine: IMine) => {
      const company = companiesWithCounts.find(
        (company: ICompany) => mine.companyFk === company.pk
      );
      if (company) {
        company.mineCount = company.mineCount ?? 0;
        company.mineCount++;
      }
    });
  
    // Update userCount
    
    companiesAccount.forEach((account: ICompanyAccount) => {
      const companyAccount = companiesWithCounts.find(
        (x:ICompany) => x.pk === account.companyFk
      );
      
      if (companyAccount) {
        companyAccount.userCount = companyAccount.userCount ?? 0;
        companyAccount.userCount++;
      }

    });
    const Enabledaccounts = arrayFromKeyedObject(accountLogins);

    companiesAccount.forEach((account: ICompanyAccount) => {
      Enabledaccounts.forEach(element => {
        const userCompanyAccount = companiesWithCounts.find(
          (x:ICompany) => x.pk === account.companyFk && account.accountLoginFk === element.pk && (element.accountFlag==12 || element.accountFlag == 30 || element.accountFlag==14 || element.accountFlag==28)
        );
  
      if (userCompanyAccount) {
        userCompanyAccount.enabledUserCount = userCompanyAccount.enabledUserCount ?? 0;
        userCompanyAccount.enabledUserCount++;
      }
    })
    });
    // Set the combined data back to state
    setCompaniesWithMines(companiesWithCounts);
    setCompanyDataKey(companyDataKey + 1);
  }, [mines, accountLogin, companies,companyAccounts]);
  
  
  useEffect(() => {
    const adminEmails = arrayFromKeyedObject(adminEmailSettings);
    setSystemAdminEmails(adminEmails);
    setAdminEmailDataKey(adminEmailDataKey + 1);
  }, [adminEmailSettings]);

  const onOpenModal = (accountLoginPk: number = 0) => {
    setAddAdminUserModalOpen(true);
  };

  const onCloseModal = () => {
    onResetModal();
  };

  const onResetModal = () => {
    setAddAdminUserModalOpen(false);
    setSelectedAccountLogin(0);
    setAddAdminUserModalId(addAdminUserModalId + 1);
  };

  const onSelectedAccountLogin = (pk: number) => {
    setSelectedAccountLogin(pk);
    setAddAdminUserModalOpen(true);
  };
  return (
    <>
      <AddCompanyModal
        open={addCompanyModalOpen}
        onClose={() => setAddCompanyModalOpen(false)}
      />
      <AdminUserModal
        key={`admin-user-modal-${addAdminUserModalId}`}
        open={addAdminUserModalOpen}
        onClose={onCloseModal}
        onReset={onResetModal}
        accountLoginPk={selectedAccountLogin}
      />
      <div style={{ backgroundColor: COLORS.WHITE_PAPER }}>
        {(hasFlag(accountLogin.accountFlag, ACCOUNT_FLAGS.system_admin) ||
          hasFlag(accountLogin.accountFlag, ACCOUNT_FLAGS.company_admin)) && (
          <>
            {hasFlag(accountLogin.accountFlag, ACCOUNT_FLAGS.system_admin) && (
              <Button
                onClick={() => setAddCompanyModalOpen(true)}
                variant="outlined"
                size="small"
              >
                Add Company
              </Button>
            )}
            <div style={{ marginTop: 16, marginBottom: 16 }}>
              <CompaniesTable
                key={"company_" + companyDataKey}
                data={[...companiesWithMines]}
              />
            </div>
            {hasFlag(accountLogin.accountFlag, ACCOUNT_FLAGS.system_admin) && (
              <>
                <div style={{ marginTop: 16 }}>
                  <Button
                    style={{ marginBottom: 5 }}
                    onClick={() => {
                      onOpenModal();
                    }}
                    variant="outlined"
                    size="small"
                  >
                    Add System Admin User
                  </Button>
                  <AccountsTable
                    title={"System Administrators"}
                    key={"account_" + accountDataKey}
                    data={[...systemAdminAccounts]}
                    onSelectedItem={onSelectedAccountLogin}
                  />
                </div>
                <div style={{ marginTop: 16 }}>
                  <AdminEmailSettingsTable
                    title={"Admin Email Notification Groups"}
                    key={"admin_email_setting_" + adminEmailDataKey}
                    data={[...systemAdminEmails]}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default Administration;
