import React from 'react';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { Typography } from '@material-ui/core';
import { strftime } from '../../utils/strftime';

interface IKeyboardDatePickerWrap extends KeyboardDatePickerProps {
    mode: "edit" | "view",
    companyDateFormat? : boolean
}

const KeyboardDatePickerWrap: React.FC<IKeyboardDatePickerWrap> = (props) => {
    const { mode, value,companyDateFormat } = props;
    return mode === 'edit' ? (
        <KeyboardDatePicker
            {...props} />
    ) : (
        <Typography>
            {
                companyDateFormat && value ? strftime('%Y-%m-%d', new Date(value as string)): value ? strftime('%m/%d/%Y', new Date(value as string)): ''
            }
        </Typography>
    )
}

export default KeyboardDatePickerWrap;