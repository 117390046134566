import { InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  getActiveCompanies,
  getUsersActivityByCompany,
} from "../../store/actions/accountActions";
import { ICompany, ICompanyWithCounts } from "../../interfaces/model/MSHAPCT/company.interface";
import MaterialTable from "material-table";
import { tableIcons } from "../shared/MaterialTableShared";
import { result } from "lodash";
import UsersUsageTable from "./UsersUsageTable";
import { IRootState } from "../../interfaces/store";
import { ICompanyAccount } from "../../interfaces/model/MSHAPCT/company-account.interface";
import { arrayFromKeyedObject } from "../../utils/collection-utils";

interface IAdministrationProps {}

const UsageStatistics: React.FC<IAdministrationProps> = (props) => {
  const dispatch = useDispatch();

  const [selectedCompany, setSelectedCompany] = useState<number | undefined>(0);
  const [allCompanies, setAllCompanies] = useState<ICompanyWithCounts[]>([]);
  const [usersData, setUsersData] = useState([] as any[]);
  const handleChangeCompany = (val: any) => {
    setSelectedCompany(val);
  };
  const companyAccounts = useSelector(
    (state: IRootState) => state.main.company_accounts
  );
  const account_login = useSelector(
    (state: IRootState) => state.main.account_logins
  );
  useEffect(() => {
    dispatch(
      getActiveCompanies((result: ICompany[]) => {
        setAllCompanies(result);
        setSelectedCompany(result[0].pk);
      })
    );
    getCompanyData(selectedCompany);
    
  }, []);

  useEffect(() => {
    getCompanyData(selectedCompany);
  }, [selectedCompany]);

  function getCompanyData(companypk: number | undefined) {
    dispatch(
      getUsersActivityByCompany({ companypk: companypk }, (result: any) => {
        setUsersData(result);
        const companyAccountsArray = arrayFromKeyedObject(companyAccounts) as ICompanyAccount[];
        const account_logins = arrayFromKeyedObject(account_login) as ICompanyAccount[];

        calculateUserCounts(account_logins, companyAccountsArray); // Call function to calculate user counts after fetching user data

      })
    );
  }

  function calculateUserCounts(usersData: any[], companyAccounts: ICompanyAccount[]) {
    const companiesWithCounts = arrayFromKeyedObject(allCompanies) as ICompanyWithCounts[];

    // Initialize counts for each company
    companiesWithCounts.forEach((company) => {
      company.enabledUserCount = 0; // Reset the enabledUserCount to avoid overwriting
    });

    // Calculate the counts by iterating over users and accounts
    companyAccounts.forEach((account: ICompanyAccount) => {
      usersData.forEach(element => {
      
        const userCompanyAccount = companiesWithCounts.find(
          (x:ICompany) => x.pk === account.companyFk && account.accountLoginFk === element.pk && (element.accountFlag==12 || element.accountFlag == 30 || element.accountFlag==14 || element.accountFlag==28)
        );
  
      if (userCompanyAccount) {
        userCompanyAccount.enabledUserCount = userCompanyAccount.enabledUserCount ?? 0;
        userCompanyAccount.enabledUserCount++;
      }
    })
    });
    setAllCompanies(companiesWithCounts); // Update the state with the updated companies list
  }


  return (
    <>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div className="flex-column-oriented mr-16" style={{ width: "25%" }}>
          {/* <label htmlFor="Mine Selector">Mine Selector</label> */}
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Company
          </InputLabel>
          <Select
            style={{ height: 40 }}
            id="inlineFormCustomSelect"
            variant="outlined"
            value={selectedCompany}
            onChange={(e) => {
              handleChangeCompany(e.target.value);
            }}
            displayEmpty
          >
            {Object.keys(allCompanies).map((key: any) => {
              const company = allCompanies[key];
              return (
                <MenuItem key={key} value={company.pk}>
                  {company?.companyName}
                  {company?.subscriptionEndDate &&
                    !isNaN(new Date(company?.subscriptionEndDate ?? '').getTime()) &&
                    new Date(new Date(company?.subscriptionEndDate).toDateString()) < new Date(new Date().toDateString())
                    ? (company?.enabledUserCount && company?.enabledUserCount > 0)
                      ? ` (expired, ${company?.enabledUserCount} enabled)`
                      : " (expired)"
                    : ""}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>
      <br></br>
      <UsersUsageTable title={"Users"} data={usersData} />
    </>
  );
};
export default UsageStatistics;
