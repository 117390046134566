import React, { useEffect, useState } from "react";
import { Button, Grid, Tooltip, Typography } from "@material-ui/core";
import { useSelector } from "../../store";
import { useDispatch } from "react-redux";
import { tableIcons } from "../shared/MaterialTableShared";
import { IRootState } from "../../interfaces/store";
import { useParams } from "react-router";
import MaterialTable from "material-table";
import { getMinesByCompany } from "../../store/actions/mineActions";
import { getCompany, updateCompany } from "../../store/actions/companyActions";
import BasicCompanyInfo from "./BasicCompanyInfo";
import Loading from "../shared/Loading";
import { getAccountLoginsByCompany } from "../../store/actions/accountLoginActions";
import { useSnackbar } from "notistack";
import { ICompany } from "../../interfaces/model/MSHAPCT/company.interface";
import { IAccountLogin } from "../../interfaces/model/MSHAPCT/account-login.interface";
import UserModal from "./UserModal";
import MineModal from "./MineModal";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteUser } from "../../store/actions/userActions";
import DialogBox from "../form/DialogBox";
import CompanyEmailSettingsTable from "./CompanyEmailSettingsTable";
import { arrayFromKeyedObject } from "../../utils/collection-utils";
import { getAllCompanyEmailSettings } from "../../store/actions/emailSettingsActions";
import { getMine, deleteMine } from "../../store/actions/mineActions";
import BusinessUnitsTable from "./BusinessUnitsTable";
import { getAllBusinessUnit } from "../../store/actions/businessUnitActions";
import RegionsTable from "./RegionsTable";
import { getAllRegion } from "../../store/actions/regionActions";
import CustomFields from "./CustomFields";

const EditCompany: React.FC = () => {
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [addUserModalId, setAddUserModalId] = useState(1);
  const [selectedAccountLogin, setSelectedAccountLogin] = useState(0);
  const [addMineModalOpen, setAddMineModalOpen] = useState(false);
  const [addMineModalId, setAddMineModalId] = useState(1);

  const emptyMine = {
    companyFk: null,
    mineName: "",
    mineId: "",
    annualUnits: "",
  };

  const [dialogSettings, setDialogSettings] = useState({
    title: "",
    open: false,
    message: "",
    fnClose: () => void 0,
    fnCancel: () => void 0,
    fnOk: (...args: any[]) => void 0,
    referenceId: 0,
  });

  const [actualMine, setActualMine] = useState(emptyMine);

  const companyEmailSettings = useSelector(
    (state: IRootState) => state.main.company_email_settings
  );

  const businessUnits = useSelector(
    (state: IRootState) => state.main.business_units
  );

  const regions = useSelector((state: IRootState) => state.main.regions);

  const [companyEmailSettingList, setCompanyEmailSettingList] = useState(
    [] as any[]
  );
  const [businessUnitList, setBusinessUnitList] = useState([] as any[]);

  const [regionList, setRegionList] = useState([] as any[]);
  const [loading, setLoading] = useState({
    company: true,
    mines: true,
    accountLogins: true,
    submit: false,
    general: true,
  });
  const [accountLoginsArr, setAccountLoginsArr] = useState(
    [] as IAccountLogin[]
  );
  const [mineList, setMineList] = useState([] as any);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { companypk } = useParams() as any;

  const companies = useSelector((state: IRootState) => state.main.companies);
  const allMines = useSelector((state: IRootState) => state.main.mines);

  const accountLogins = useSelector(
    (state: IRootState) => state.main.account_logins
  );

  const companyAccounts = useSelector(
    (state: IRootState) => state.main.company_accounts
  );

  const handleLoadingChange = (change: any) => {
    const newVal = { ...loading, ...change };
    newVal.general = false;
    Object.keys(newVal).forEach((key) => {
      if (key !== "general" && newVal.key === true) {
        newVal.general = true;
      }
    });
    setLoading(newVal);
  };

  const onSave = (editableCompany: ICompany, childOnSuccess: any) => {
    handleLoadingChange({ submit: true });
    const data = {
      ...editableCompany,
      contractorCompany: editableCompany.hasContractorCompany,
    };
    dispatch(
      updateCompany(data, () => {
        enqueueSnackbar("Company Updated", { variant: "success" });
        handleLoadingChange({ submit: false });
        childOnSuccess();
      })
    );
  };

  useEffect(() => {
    // const token = jwtToken();
    // const companyid = token.CompanyId;
    const accountLoginsTemp = Object.values(accountLogins);

    const companyAccountsTemp = Object.values(companyAccounts).filter(
      (al) => al.companyFk === Number(companypk)
    );
    const companyAccountsALFK = companyAccountsTemp.map(
      (ca) => ca.accountLoginFk
    );

    setAccountLoginsArr(
      accountLoginsTemp.filter((al) => companyAccountsALFK.includes(al.pk!))
    );
  }, [accountLogins, companyAccounts]);

  useEffect(() => {
    const adminEmails = arrayFromKeyedObject(companyEmailSettings);
    setCompanyEmailSettingList(adminEmails);
  }, [companyEmailSettings]);

  useEffect(() => {
    const newBusinessUnits = arrayFromKeyedObject(businessUnits);
    setBusinessUnitList(newBusinessUnits);
  }, [businessUnits]);

  useEffect(() => {
    const newRegions = arrayFromKeyedObject(regions);
    setRegionList(newRegions);
  }, [regions]);

  useEffect(() => {
    dispatch(getAllRegion(companypk));
    dispatch(getAllBusinessUnit(companypk));
    dispatch(getAllCompanyEmailSettings(companypk));
    dispatch(
      getAccountLoginsByCompany({ companypk: companypk }, () => {
        handleLoadingChange({ accountLogins: false });
      })
    );
    dispatch(
      getCompany({ companypk: companypk }, () => {
        handleLoadingChange({ company: false });
      })
    );
    dispatch(
      getMinesByCompany({ companypk: companypk }, () => {
        handleLoadingChange({ mines: false });
      })
    );
  }, []);

  const currentCompany = companies[companypk];

  useEffect(() => {
    const mines = Object.values(allMines).filter(
      (el) => el.companyFk === Number(companypk)
    );
    setMineList(mines);
  }, [allMines, companypk]);

  // const accountLoginsArr = Object.values(accountLogins);

  const onGetMineDetail = (id: any) => {
    dispatch(
      getMine({ pk: id }, (data: any) => {
        setActualMine(data);
        setAddMineModalOpen(true);
      })
    );
  };

  const mineColumns = [
    { title: "Name", field: "mineName", sorting: true },
    { title: "ID", field: "mineId", sorting: true },
    { title: "Status", field: "mineStatus" },
    { title: "Material", field: "mineMaterial", sorting: true },
    { title: "Annual Units", field: "annualUnits", sorting: true },
    { title: "MSHA Update", field: "mshaHistoryUpdateFreq" },
    {
      title: "Actions",
      render: (row: any) => {
        return (
          <div>
            <Tooltip title="Edit" placement="top">
              <EditIcon
                style={{ marginLeft: 12, fontSize: 16, cursor: "pointer" }}
                className="transparent75"
                onClick={() => {
                  if (row && row.pk) {
                    onGetMineDetail(row.pk);
                  }
                }}
              />
            </Tooltip>

            <Tooltip title="Delete" placement="top">
              <DeleteIcon
                style={{ marginLeft: 12, fontSize: 16, cursor: "pointer" }}
                className="transparent75"
                onClick={() => {
                  if (row.pk) {
                    onShowDialogDeleteMine(row.pk);
                  }
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const accountLoginsColumns = [
    { title: "Last name", field: "lastName", sorting: true },
    { title: "First name", field: "firstName", sorting: true },
    { title: "Email", field: "emailAddress", sorting: true },
    { title: "Enabled", field: "accountFlag", render:(Rowdata:any)=>{
      return (Rowdata.accountFlag ==12 || Rowdata.accountFlag == 30 || Rowdata.accountFlag==14 || Rowdata.accountFlag==28) ? "true": "false"
    },sorting: true },

    {
      title: "Actions",
      field: "pk",
      render: (rowData: any) => {
        return (
          <div>
            <Tooltip title="Edit" placement="top">
              <EditIcon
                style={{ fontSize: 16, cursor: "pointer" }}
                className="transparent75"
                onClick={() => {
                  if (rowData.pk) {
                    onOpenModal(Number(rowData.pk));
                  }
                }}
              />
            </Tooltip>

            <Tooltip title="Delete" placement="top">
              <DeleteIcon
                style={{ marginLeft: 12, fontSize: 16, cursor: "pointer" }}
                className="transparent75"
                onClick={() => {
                  if (rowData.pk) {
                    onShowDialogDeleteUser(rowData.pk);
                  }
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const onDeleteUserAction = (id: number) => {
    const updatedLoading = { ...loading, general: true };
    setLoading(updatedLoading);

    dispatch(
      deleteUser(
        { userId: id, companyId: companypk },
        onDeleteUserSucess,
        onDeleteDialogActionFail
      )
    );
  };

  const onDeleteMineAction = (id: number) => {
    const updatedLoading = { ...loading, general: true };
    setLoading(updatedLoading);
    dispatch(deleteMine(id, onDeleteMineSucess, onDeleteDialogActionFail));
  };

  const onDeleteUserSucess = (wasAdded: boolean) => {
    const updatedLoading = { ...loading, general: false };
    setLoading(updatedLoading);
    setDialogSettings({ ...dialogSettings, open: false });
    enqueueSnackbar("User was successfully deleted.", { variant: "success" });
  };

  const onDeleteMineSucess = (wasAdded: boolean) => {
    const updatedLoading = { ...loading, general: false };
    setLoading(updatedLoading);
    enqueueSnackbar("Mine was successfully deleted.", { variant: "success" });
    setDialogSettings({ ...dialogSettings, open: false });
  };

  const onDeleteDialogActionFail = (message: string) => {
    const updatedLoading = { ...loading, general: false };
    setLoading(updatedLoading);
    enqueueSnackbar(message, {
      variant: "error",
    });
  };

  const onCancelOrCloseDialogAction = () => {
    setDialogSettings({ ...dialogSettings, open: false });
  };

  const onOpenModal = (accountLoginPk: number = 0) => {
    setAddUserModalOpen(true);
    setSelectedAccountLogin(accountLoginPk);
  };

  const onCloseModal = () => {
    onResetModal();
  };

  const onResetModal = () => {
    setAddUserModalOpen(false);
    setSelectedAccountLogin(0);
    setAddUserModalId(addUserModalId + 1);
  };

  const onCloseMineModal = () => {
    onResetMineModal();
  };

  const onResetMineModal = () => {
    setAddMineModalOpen(false);
    setActualMine(emptyMine);
    setAddMineModalId(addMineModalId + 1);
  };

  const onShowDialogDeleteUser = (id: number) => {
    onShowDeleteDialogBox(
      "Are you sure you want to delete the user?",
      "This action cannot be undone.",
      onCancelOrCloseDialogAction,
      onCancelOrCloseDialogAction,
      onDeleteUserAction,
      id
    );
  };

  const onShowDialogDeleteMine = (id: number) => {
    onShowDeleteDialogBox(
      "Are you sure you want to delete the mine?",
      "This action cannot be undone.",
      onCancelOrCloseDialogAction,
      onCancelOrCloseDialogAction,
      onDeleteMineAction,
      id
    );
  };

  const onShowDeleteDialogBox = (
    title: string,
    message: string,
    fnClose: any,
    fnCancel: any,
    fnOk: any,
    id: number
  ) => {
    const updateState = {
      ...dialogSettings,
      title,
      message,
      fnClose,
      fnCancel,
      fnOk,
      open: true,
      referenceId: id,
    };
    setDialogSettings(updateState);
  };

  if (currentCompany) {
    return (
      <>
        <MineModal
          key={`mine-modal-${addMineModalId}`}
          open={addMineModalOpen}
          onClose={onCloseMineModal}
          onReset={onResetMineModal}
          data={actualMine}
        ></MineModal>

        <UserModal
          key={`user-modal-${addUserModalId}`}
          accountLoginPk={selectedAccountLogin}
          open={addUserModalOpen}
          onClose={onCloseModal}
          onReset={onResetModal}
        />
        <DialogBox
          id="dialogbox1"
          open={dialogSettings.open}
          onClose={dialogSettings.fnClose}
          onCancel={dialogSettings.fnCancel}
          onOk={() => {
            dialogSettings.fnOk(dialogSettings.referenceId);
          }}
          title={dialogSettings.title}
          innerText={dialogSettings.message}
        ></DialogBox>
        <Loading loading={loading.general} />
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <BasicCompanyInfo company={currentCompany} onSave={onSave} />
          </Grid>
          <Grid item>
            <Button
              style={{ marginBottom: 5 }}
              onClick={() => setAddMineModalOpen(true)}
              variant="outlined"
              size="small"
            >
              Add Mine
            </Button>
            <MaterialTable
              title="Mines"
              icons={tableIcons}
              columns={mineColumns}
              data={mineList}
              options={{
                sorting: true,
                headerStyle: {
                  zIndex: 8,
                },
                paging: true,
                pageSize: 5,
                draggable: false,
                //actionsColumnIndex: -1
              }}
            />
          </Grid>
          <Grid item>
            <BusinessUnitsTable data={businessUnitList}></BusinessUnitsTable>
          </Grid>
          <Grid item>
            <RegionsTable data={regionList}></RegionsTable>
          </Grid>
          <Grid item>
            <Button
              style={{ marginBottom: 5 }}
              onClick={() => {
                onOpenModal();
              }}
              variant="outlined"
              size="small"
            >
              Add User
            </Button>
            <MaterialTable
              title="Users"
              icons={tableIcons}
              columns={accountLoginsColumns}
              data={accountLoginsArr}
              options={{
                sorting: true,
                headerStyle: {
                  zIndex: 8,
                },
                paging: true,
                pageSize: 5,
                draggable: false,
                //actionsColumnIndex: -1
              }}
            />
          </Grid>
          <Grid item>
            <CompanyEmailSettingsTable
              title="Company Email Notification Groups"
              data={companyEmailSettingList}
            />
          </Grid>
          <Grid item>
            <CustomFields />
          </Grid>
        </Grid>
      </>
    );
  }

  return <Typography variant="h6">No company found.</Typography>;
};

export default EditCompany;
