// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Button, TextField, Grid, InputLabel } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Loading from "../shared/Loading";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { IRootState } from "../../interfaces/store";
import { IMine } from "../../interfaces/model/MSHAPCT/mine.interface";
import { IInspection } from '../../interfaces/model/MSHAPCT/inspection.interface'
import { dateFormatted } from '../../utils/date-utils'
import {
  updateInspection,
} from "../../store/actions/inspectionActions";
import { useSnackbar } from "notistack";
import { numberPeriodInputFilter } from '../../utils/general-utils'
interface IEditInspectionModalProps {
  open: boolean;
  onClose: Function;
  mine: IMine;
  selectedInspection?: IInspection
}

const EditInspectionModal: React.FC<IEditInspectionModalProps> = (props) => {
  const [clientInspectionHours, setClientInspectionHours] = useState("");
  const [mSHAInspectionHours, setMSHAInspectionHours] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [notes, setNotes] = useState("");
  const [matchedInspection, setMatchedInspection] = useState(false);
  const [eventNumber, setEventNumber] = useState(props.selectedInspection?.eventNumber)
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const [processsing, setProcesssing] = useState(false);

  const inspections = useSelector(
    (state: IRootState) => state.flatfile.inspections
  );

  useEffect(() => {
    const inspectionBeginDate = inspections[
      props.selectedInspection?.eventNumber
    ]?.inspectionBeginDt.slice(0, 10);
    const inspectionEndDate = inspections[props.selectedInspection?.eventNumber]?.inspectionEndDt.slice(
      0,
      10
    );
    const mSHAInspectionHours =
      inspections[props.selectedInspection?.eventNumber]?.totalInspHours -
      inspections[props.selectedInspection?.eventNumber]?.totalOnSiteHrsSpvrTrainee;

      if (props.selectedInspection?.startDate){
        const startDate = new Date(props.selectedInspection?.startDate)
        setStartDate(dateFormatted(props.selectedInspection?.startDate, 'yyyymmdd', '-'));
      }
      if (props.selectedInspection?.endDate){
        const endDate = new Date(props.selectedInspection?.startDate)
        setEndDate(dateFormatted(props.selectedInspection?.endDate, 'yyyymmdd', '-'));
      }
      if (props.selectedInspection?.notes){
        setNotes(props.selectedInspection?.notes)
      }
      if (props.selectedInspection?.vpidHours){
        setClientInspectionHours(props.selectedInspection?.vpidHours)
      }

    setMSHAInspectionHours(
      isNaN(mSHAInspectionHours) ? 0 : mSHAInspectionHours
    );
  }, [props.selectedInspection, props.open]);

  const resetForm = () => {
    setClientInspectionHours("");
    setMSHAInspectionHours("");
    setStartDate("");
    setEndDate("");
    setNotes("");
  };

  const handleClose = () => {
    resetForm();
    props.onClose();
  };

  // const searchEvent = () => {
  //   if (props.selectedInspection?.eventNumber) {
  //     dispatch(
  //       getInspectionByEventNumber(
  //         { mineid: props.mine.mineId, eventNumber },
  //         () => {
  //           // handle the onSuccess here, we have data to populate form
  //           //
  //         }
  //       )
  //     );
  //   } else {
  //     resetForm();
  //   }
  // };

  const submitForm = () => {

	let safeClientInspectionHours = clientInspectionHours
	if (safeClientInspectionHours !== ""){
		safeClientInspectionHours = Number(safeClientInspectionHours)
	}

    const data = {
      pk: props.selectedInspection?.pk,
      mineFk: props.mine.pk,
      startDate: `${startDate}T00:00:00`,
      endDate: `${endDate}T00:00:00`,
      vpidHours: safeClientInspectionHours,
      eventNumber: eventNumber,
      dataSource: 1,
      inspectionStatus: null,
      notes: notes,
    };
    //dispatch add company, show loader wait, and then close
    // setProcesssing(true);

    // const onSuccess = (data: Object) => {
    //   console.log("Happening 4");
    //   history.push(`/Administration/Company/${data.pk}/Edit`);
    //   props.onClose();
    //   setProcesssing(false);
    //   enqueueSnackbar("Inspection added", { variant: "success" });
    // };
    setProcesssing(true);
    dispatch(
      updateInspection(
        data,
        (primaryKey: number) => {
          setProcesssing(false);
          handleClose();
        },
        () => {
          setProcesssing(false);
        }
      )
    );

    // dispatch(addInspection(data, onSuccess));
  };

  return (
    <>
      <Loading loading={processsing} />
      <Dialog
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <MuiDialogTitle>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 3, marginTop: 10 }}>
              <Typography variant="h6">Edit Inspection</Typography>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              <IconButton aria-label="close" onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <Grid
            container
            spacing={3}
            style={{ minHeight: "20rem", padding: "12px" }}
          >
            <Grid className="add-inspection-columns">
              <Grid item>
                <InputLabel htmlFor="my-input">
                  Inspection Start Date
                </InputLabel>
                <TextField
                  disabled={false}
                  size="small"
                  id="start_date"
                  type="date"
                  variant="outlined"
                  value={startDate}
				  onChange={(e) => {
					setStartDate(e.target.value)
				}}
                />
              </Grid>
              <Grid item>
                <InputLabel htmlFor="my-input">Inspection End Date</InputLabel>
                <TextField
                  disabled={false}
                  size="small"
                  id="end_date"
                  type="date"
                  variant="outlined"
                  value={endDate}
				  onChange={(e) => {
					setEndDate(e.target.value)
				}}
                />
              </Grid>
              <Grid alignItems="flex-end" container wrap="nowrap">
                <Grid item xs={12}>
                  <InputLabel htmlFor="my-input">Event Number</InputLabel>
                  <TextField
                    size="small"
                    id="event_number"
                    variant="outlined"
                    value={eventNumber}
                    onChange={(event) =>
                      setEventNumber(event?.target.value.replace(/[^a-zA-Z0-9]/g, ""))
                    }
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  {/* <Button
                    variant={"outlined"}
                    autoFocus
                    onClick={() => searchEvent()}
                    color="primary"
                    style={{ marginLeft: "5px", padding: "7px" }}
                  >
                    Search
                  </Button> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid className="add-inspection-columns">
              <Grid item xs={12}>
                <InputLabel htmlFor="my-input">
                  Client Inspection Hours
                </InputLabel>
                <TextField
                  disabled={false}
                  size="small"
                  id="client_inspection_hours"
                  onChange={(event) =>
                    setClientInspectionHours(
						numberPeriodInputFilter(event?.target.value)
                    )
                  }
                  variant="outlined"
                  value={clientInspectionHours}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor="my-input">
                  MSHA Inspection Hours
                </InputLabel>
                <TextField
                  disabled={false}
                  size="small"
                  id="msha_inspection_hours"
                  onChange={(event) =>
                    setMSHAInspectionHours(numberPeriodInputFilter(event?.target.value))
                  }
                  variant="outlined"
                  value={mSHAInspectionHours}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor="my-input">Notes</InputLabel>
                <TextField
                  disabled={false}
                  multiline
                  size="small"
                  id="notes"
                  rows={6}
                  onChange={(event) => setNotes(event?.target.value)}
                  variant="outlined"
                  value={notes}
                />
              </Grid>
            </Grid>
          </Grid>
        </MuiDialogTitle>
        <DialogActions>
          <Button
            variant={"outlined"}
            autoFocus
            onClick={() => submitForm()}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditInspectionModal;
