import { Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router";
import MaterialTable from "material-table";
import { tableIcons } from "../shared/MaterialTableShared";
import { ICompany } from "../../interfaces/model/MSHAPCT/company.interface";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import BarChartIcon from "@material-ui/icons/BarChart";
import { deleteCompany } from "../../store/actions/companyActions";
import StandardModal from "../shared/StandardModal";
import { useDispatch } from "react-redux";
import Loading from "../shared/Loading";
import { useSnackbar } from "notistack";
import { format } from "date-fns";

interface ICompaniesTable {
  data: ICompany[];
}

const CompaniesTable: React.FC<ICompaniesTable> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();

  const [deleteCompanyModalOpen, setDeleteCompanyModalOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);
  const [processing, setProcessing] = useState(false);

  const columns = [
    { title: "Name", field: "companyName", sorting: true },
    { title: "Publicly Traded", field: "isPubliclyTraded", sorting: true },
    { title: "Mine State Allowed", field: "isMineStateAllowed", sorting: true },
    {
      title: "Email Notifications",
      field: "isEmailNotificationEnabled",
      sorting: true,
    },
    { title: "User Count(Enabled)", field: "userCount",        render: (rowData: any) => `${rowData.userCount} (${ rowData.enabledUserCount})`,  // Custom render function
    sorting: true },
    { title: "Mine Count", field: "mineCount", sorting: true },
    { title: "Subscription Date", field: "subscriptionEndDate",render: (rowData: any) =>
      rowData.subscriptionEndDate? format(new Date(rowData.subscriptionEndDate), "yyyy-MM-dd") : "", sorting: true },
    {
      title: "Actions",
      field: "mineCount",
      render: (rowData: any) => {
        return (
          <div>
            <Tooltip title="Edit" placement="top">
              <EditIcon
                style={{ fontSize: 16 }}
                className="transparent75"
                onClick={() => {
                  if (rowData && rowData.pk) {
                    history.push(`Configuration/Company/${rowData.pk}/Edit`);
                  }
                }}
              />
            </Tooltip>

            <Tooltip title="Analytics" placement="top">
              <BarChartIcon
                style={{ marginLeft: 12, fontSize: 16 }}
                className="transparent75"
              />
            </Tooltip>

            <Tooltip title="Delete" placement="top">
              <DeleteIcon
                style={{ marginLeft: 12, fontSize: 16 }}
                className="transparent75"
                onClick={() => {
                  if (rowData && rowData.pk) {
                    console.log("rowData", rowData);
                    setSelectedCompany(rowData);
                    setDeleteCompanyModalOpen(true);
                    //deleteCompany(rowData)
                  }
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Loading loading={processing} />
      <StandardModal
        open={deleteCompanyModalOpen}
        onClose={() => setDeleteCompanyModalOpen(false)}
        actions={[
          {
            title: "Cancel",
            callback: () => {
              setDeleteCompanyModalOpen(false);
            },
          },
          {
            title: "Yes",
            callback: () => {
              setProcessing(true);
              dispatch(
                deleteCompany(
                  selectedCompany,
                  () => {
                    setDeleteCompanyModalOpen(false);
                    setProcessing(false);
                    enqueueSnackbar(
                      `${
                        selectedCompany?.companyName || "Company"
                      } was successfully deleted.`,
                      {
                        variant: "success",
                      }
                    );
                  },
                  () => {
                    enqueueSnackbar(
                      `There was an error deleting ${
                        selectedCompany?.companyName || "Company"
                      }`,
                      {
                        variant: "error",
                      }
                    );
                  }
                )
              );
            },
          },
        ]}
        customActions={[]}
        paragraphs={[
          `Are you sure you want to delete ${selectedCompany?.companyName} company. This will delete all associated mines contractors, company accounts, citations, citation meta data, citation version, citation meta data versions, associated business units, inpsections, associated regions and it cannot be undone.`,
        ]}
        title={"Delete Company"}
      />
      <MaterialTable
        icons={tableIcons}
        title="Companies"
        columns={columns}
        data={props.data}
        options={{
          sorting: true,
          headerStyle: {
            zIndex: 8,
          },
          paging: true,
          pageSize: Math.min(props.data.length, 10),
          draggable: false,
          //    actionsColumnIndex: -1,
          rowStyle: {
            height: 20,
          },
        }}
        onRowClick={(event, rowData, togglePanel) => {
          // if (rowData && rowData.pk) {
          //     history.push(`/Company/${rowData.pk}`);
          // }
        }}
        //actions={[
        //                     {
        //     icon: () => <span style={{fontSize: 12}}>
        //         <span style={{position: 'absolute', left: -4, top: -4}}>
        //         <EditIcon
        //         style={{fontSize: 24, }}
        //         className="transparent75"
        //         />
        //             </span>
        //             </span>,
        //     tooltip: 'Edit Company',
        //     onClick: (event, company: any) => {

        //     },

        // },
        // {
        //     icon: () => <EditIcon

        //     className="transparent75"
        //     />,
        //     tooltip: 'Edit Company',
        //     onClick: (event, company: any) => {

        //     },
        //     iconProps: {fontSize: 'small'}

        // },
        // {
        //     icon: () => <BarChartIcon

        //     className="transparent75"
        //     />,
        //     tooltip: 'Mine State',
        //     onClick: (event, company: any) => {

        //     }
        // },
        // {
        //     icon: () => <DeleteIcon
        //     // style={{ fontSize: 12 }}
        //     className="transparent75"
        //     />,
        //     tooltip: 'Delete Company',
        //     onClick: (event, company: any) => {

        //     }
        // },
        //]}
      />
    </>
  );
};

export default CompaniesTable;
