import { Button, Container, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { COLORS } from "../../enums/layout-enums";
import { ICompany } from "../../interfaces/model/MSHAPCT/company.interface";
import CheckboxWrap from "../form/CheckboxWrap";
import TextFieldWrap from "../form/TextFieldWrap";
import KeyboardDatePickerWrap from "../form/KeyboardDatePickerWrap";
import { format } from "date-fns";

export interface BasicCompanyInfoProps {
  company?: ICompany;
  onSave: Function;
}

const BasicCompanyInfo: React.FC<BasicCompanyInfoProps> = (
  props: BasicCompanyInfoProps
) => {
  const { company } = props;
  const [editableCompany, setEditableCompany] = useState<ICompany>({});
  const [editMode, setEditMode] = useState<boolean>(false);
  const [errors, setErrors] = useState({} as any);

  const maybeSetFalse = (obj: any, val: string) => {
    if (!obj[val]) {
      obj[val] = false;
    }
  };
  useEffect(() => {

    if (company) {
      let newVal = {
        ...company,
      };
      maybeSetFalse(newVal, "isEmailNotificationEnabled");
      maybeSetFalse(newVal, "alwaysOverrideMshaimport");
      maybeSetFalse(newVal, "isPubliclyTraded");
      maybeSetFalse(newVal, "isMineStateAllowed");
      maybeSetFalse(newVal, "hasContractorCompany");
      setEditableCompany({ ...newVal });
    }
  }, [company, setEditableCompany]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditableCompany({
      ...editableCompany,
      [event.target.name]: event.target.checked,
    });
  };

  const onSave = () => {
    const _errors: any = {};
    if (!editableCompany.companyName || editableCompany.companyName.trim() === ""){ 
      _errors.companyName = "Company Name is required";
    setErrors(_errors);
		return;
    }else(
      setErrors("")
    )
    props.onSave(editableCompany, () => setEditMode(false))
  };

  if (company) {
    return (
      <Container
        style={{
          backgroundColor: COLORS.WHITE_BACKGROUND,
          paddingTop: 16,
          paddingBottom: 16,
        }}
      >
        <Grid container direction="column" spacing={1}>
          <Typography variant="h6">Company Profile</Typography>
          <Grid container item direction="row" spacing={1} alignItems="center">
            <Typography variant="body1" component="span">
              Name: &nbsp;
            </Typography>
            <TextFieldWrap
              mode={editMode ? "edit" : "view"}
              variant="outlined"
              value={editableCompany.companyName}
              size="small"
              onChange={(event) =>
                setEditableCompany({
                  ...editableCompany,
                  companyName: event?.target.value,
                })
              }
            />
          </Grid>
          <em>{errors.companyName}</em>

          <Grid container item direction="row" spacing={1} alignItems="center">
            <CheckboxWrap
              mode={editMode ? "edit" : "view"}
              onChange={handleCheckboxChange}
              name="isPubliclyTraded"
              color="primary"
              checked={editableCompany.isPubliclyTraded}
              id="publicly_traded"
              label="Publicly Traded"
            />
          </Grid>
          <Grid container item direction="row" spacing={1} alignItems="center">
            <CheckboxWrap
              mode={editMode ? "edit" : "view"}
              onChange={handleCheckboxChange}
              name="isMineStateAllowed"
              color="primary"
              checked={editableCompany.isMineStateAllowed}
              id="mine_state_allowed"
              label="Mine state Allowed"
            />
          </Grid>
          <Grid container item direction="row" spacing={1} alignItems="center">
            <CheckboxWrap
              mode={editMode ? "edit" : "view"}
              onChange={handleCheckboxChange}
              checked={editableCompany.alwaysOverrideMshaimport}
              name="alwaysOverrideMshaimport"
              color="primary"
              id="always_override_msha_import"
              label="Always override MSHA import"
            />
          </Grid>
          <Grid container item direction="row" spacing={1} alignItems="center">
            <CheckboxWrap
              mode={editMode ? "edit" : "view"}
              onChange={handleCheckboxChange}
              checked={editableCompany.isEmailNotificationEnabled}
              name="isEmailNotificationEnabled"
              color="primary"
              id="enable_email_notification"
              label="Enable email notification"
            />
          </Grid>

          <Grid container item direction="row" spacing={1} alignItems="center">
            <CheckboxWrap
              mode={editMode ? "edit" : "view"}
              onChange={handleCheckboxChange}
              checked={editableCompany.hasContractorCompany}
              name="hasContractorCompany"
              color="primary"
              id="has_contractor_company"
              label="Contractor Company"
            />
          </Grid>
          <Grid container item direction="row" spacing={1} alignItems="center">
            <Typography variant="body1" component="span">
              Contractor Name: &nbsp;
            </Typography>
            <TextFieldWrap
              mode={editMode ? "edit" : "view"}
              variant="outlined"
              value={editableCompany.contractorName}
              size="small"
              onChange={(event) =>
                setEditableCompany({
                  ...editableCompany,
                  contractorName: event?.target.value,
                })
              }
            />
          </Grid>
          <Grid container item direction="row" spacing={1} alignItems="center">
            <Typography variant="body1" component="span">
              Contractor ID: &nbsp;
            </Typography>
            <TextFieldWrap style={{ marginTop:10}}
              mode={editMode ? "edit" : "view"}
              variant="outlined"
              value={editableCompany.contractorId}
              size="small"
              onChange={(event) =>
                setEditableCompany({
                  ...editableCompany,
                  contractorId: event?.target.value,
                })
              }
            />
          </Grid>
          <div  className="row"
            style={{ maxWidth: 650, display: "flex", alignItems:"baseline"}}
          > <Typography variant="body1" component="span">
          Subscription End Date : &nbsp;
        </Typography>
            <KeyboardDatePickerWrap
              companyDateFormat  = {true}
              label="Subscription End Date"
              size="small"
              mode={editMode ? "edit" : "view"}
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              margin="none"
              value={editableCompany.subscriptionEndDate}             
              onChange={(event:any) => {
                if (event) {
                  setEditableCompany({
                    ...editableCompany,
                    subscriptionEndDate: (event as string), // Convert to Date if valid
                  });
                }
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </div>
          <Grid
            item
            container
            direction="row-reverse"
            spacing={1}
            alignItems="center"
          >
            {editMode ? (
              <>
                <Button
                  onClick={() =>
                    onSave()
                  }
                  variant="contained"
                  size="small"
                >
                  Save
                </Button>
                <Button
                  onClick={() => setEditMode(false)}
                  color="primary"
                  variant="outlined"
                  size="small"
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button
                onClick={() => setEditMode(true)}
                variant="contained"
                size="small"
              >
                Edit
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  }

  return null;
};

export default BasicCompanyInfo;
